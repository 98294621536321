<template>
  <div class="fb-document">
    <ul class="document-list border">
      <li class="document-summary">
        <span class="document-name">文书</span>
      </li>
      <li class="document-item"
          v-for="(document, index) in documents"
          :key="index">
        <span class="document-type"
              @click="showDocument(document)">{{ document.name }}
          <i class="iconfont icon-download"></i>
        </span>
        <span class="document-date">{{ getTime(document.createTime) }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { caseBase } from '~api-config'
export default {
  data () {
    return {
      documents: [
      ]
    }
  },
  computed: {
    caseId () {
      return this.$route.params.caseId
    },
    ...mapState('user', {
      userId: state => state.userInfo.id
    })
  },
  methods: {
    getTime (value) {
      const a = new Date(value)
      return `${a.getFullYear()}年${a.getMonth() + 1}月${a.getDate()}日`
    },
    // showDocument (item) {
    //   const u = navigator['userAgent']
    //   if (u === 'fb-Android-Webview') {
    //     // eslint-disable-next-line
    //     AND2JS.showWritPreview(item.id, item.name)
    //   } else if (u === 'fb-iOS-Webview') {
    //     window.webkit.messageHandlers.showWritPreview.postMessage({ id: item.id, name: item.name })
    //   } else {
    //     // console.log('环境不对')
    //   }
    // }
    showDocument (item) {
      const query = {
        documentId: item.documentId,
        userId: this.userId,
        caseId: this.caseId
      }
      this.$router.push({ path: '/editor', query })
    }
  },
  mounted () {
    this.$axios.get(`${caseBase}/new-design/catalog/list?style=1&caseId=${this.$route.params.caseId}`)
      .then(res => {
        // console.log('文书', res)
        this.documents = res.data.data.catalogs
      })
  }
}
</script>

<style lang="stylus" scoped>
.fb-document
  .document-list
    width 90%
    margin 20px auto
    font-size 16px
    box-shadow 0px 1px 8px 1px rgba(64, 180, 233, 0.23)
    border-radius 4px
    background-color white
    .document-summary
      padding 0 10px
      height 50px
      .document-name
        color #00afff
        line-height 50px
      .document-edit
        float right
        line-height 32px
    .document-item
      padding 0 10px
      height 50px
      display flex
      justify-content space-between
      align-items center
      border-top 1px solid #80808024
      .document-type
        white-space nowrap
        overflow hidden
        text-overflow ellipsis
        line-height 50px
      .iconfont
        font-size 15px
        margin 0 10px
      .document-download
        color #00afff
      .document-link
        color #00afff
      .document-date
        white-space nowrap
      .document-date-edit
        margin-left 18px
      .icon-checked
        font-size 20px
        color grey
      .document-checked
        font-size 20px
        color #00a3ff
  .delete-document
    position fixed
    width 100%
    height 50px
    bottom 0
    z-index 1500
    background-color white
    .iconfont
      color #00a3ff
      margin-left 45%
      font-size 30px
      line-height 50px
</style>
