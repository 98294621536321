var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "fb-document" }, [
    _c(
      "ul",
      { staticClass: "document-list border" },
      [
        _vm._m(0),
        _vm._l(_vm.documents, function (document, index) {
          return _c("li", { key: index, staticClass: "document-item" }, [
            _c(
              "span",
              {
                staticClass: "document-type",
                on: {
                  click: function ($event) {
                    return _vm.showDocument(document)
                  },
                },
              },
              [
                _vm._v(_vm._s(document.name) + " "),
                _c("i", { staticClass: "iconfont icon-download" }),
              ]
            ),
            _c("span", { staticClass: "document-date" }, [
              _vm._v(_vm._s(_vm.getTime(document.createTime))),
            ]),
          ])
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "document-summary" }, [
      _c("span", { staticClass: "document-name" }, [_vm._v("文书")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }